import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpRequestService } from '../../core/services/http-request/http-request.service';
import { ResponseBase, Response } from '../../core/models/response.model';
import { VincularInstituicaoParams } from '../vincular-conta/vincular-instituicao/vincular-instituicao.model';
import { AlterarFotoPerfilModel } from './usuario.model';
import { LocalStorageService } from '../../core/services/local-storage/local-storage.service';
import { environment } from '../../../environments/environment';
import { LoginResult, UsuarioInfo } from '../autenticacao/login/login.model';
import { IntituicaoUsuarioModel } from './perfil/instituicoes/instituicoes.model';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(
    private _httpRequest: HttpRequestService,
    private _storageService: LocalStorageService
  ) {}

  /**
   * Requisição HTTP para realizar o vínculo de uma nova instituição ao usuário
   */
  vincularInstituicao(
    params: VincularInstituicaoParams
  ): Observable<ResponseBase> {
    return this._httpRequest.post<ResponseBase>(
      '/v1/usuario/vincular-instituicao',
      params
    );
  }

  /**
   * Requisição HTTP para realizar a troca de foto do usuário
   */
  alterarAvatar(psAvatar?: string): Observable<ResponseBase> {
    let params: AlterarFotoPerfilModel = {
      avatar: psAvatar,
    };

    return this._httpRequest
      .put<ResponseBase>('/v1/usuario/alterar-foto', params)
      .pipe(
        tap((p) => {
          if (p.success) {
            let lInfoUsuarioLogado = this._storageService.get<LoginResult>(
              environment.keys.userInfo
            );
            lInfoUsuarioLogado!.usuarioInfo.avatar = psAvatar;
            this._storageService.set(
              environment.keys.userInfo,
              lInfoUsuarioLogado
            );
          }
        })
      );
  }

  /**
   * Requisição HTTP para realizar a busca de instituições vinculadas ao usuário
   */
  obterInstituicoes(): Observable<Response<IntituicaoUsuarioModel[]>> {
    return this._httpRequest.get<Response<IntituicaoUsuarioModel[]>>(
      '/v1/usuario/instituicoes'
    );
  }
}
